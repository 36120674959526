<template>
    <div class="container">
        <h1>Livros</h1>
        <booksRowGallery :loading="loading" :key="key_atualizacao" :targetArray="acervo"></booksRowGallery>
        {{first_book}}
        {{last_book}}
        {{previous_start_points}}

        <!-- Paginação -->
        <div class="row mt-5" style="place-content: center;">
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <b-button :disabled="historicoNavIsFalse" class="page-link" v-on:click="paginaAnterior()">Página anterior</b-button>
                    <b-button class="page-link" v-on:click="proximaPagina()">Próxima página</b-button>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import booksRowGallery from '@/components/booksRowGallery.vue';
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';


// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: {
        booksRowGallery,
        // Splide,
        // SplideSlide,
    },
    data() {
        return {
            key_atualizacao: 0,
            acervo: [],
            loading: true,
            curso_escolhido: "",
            num_startAt: 0,
            num_limit: 20,
            first_book: 0,
            last_book: 0,
            previous_start_points: [],
        }
    },
    computed: {
        historicoNavIsFalse: function(){
            // console.log('btn', this.previous_start_points.length > 0)
            // console.log('start atual: ', this.first_book)
            // console.log('primeiro start point', this.previous_start_points[0])
            // O btn de voltar ficará desativado sempre que estiver na primeira página de resultados. Seja por ainda não
            //ter saido da primiera ou por ter voltado a primeira página.
            return this.previous_start_points.length === 0 || this.first_book === this.previous_start_points[0]
        }
    },
    methods: {
        retornaLivrosFirebasePorCurso: function(curso, partida, limite){
            console.log(curso, typeof curso, this.num_startAt, typeof this.num_startAt, this.num_limit, typeof this.num_limit)
            let respostaRaw = [];
            db.collection("acervo-completo").where("tags_area", "array-contains", curso)
            .orderBy("index")
            .startAt(parseInt(partida))
            .limit(parseInt(limite))
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    respostaRaw.push(doc.data())
                });
                this.acervo = respostaRaw
                // Atualiza o elemento. Essa key garante que o elemento inteiro seja atualizado, de forma que ele reinicie
                this.key_atualizacao++
                // Computando a próxima página
                this.first_book = parseInt(respostaRaw[0].index)
                this.last_book = parseInt(respostaRaw[respostaRaw.length-1].index)
                
                console.log(this.acervo)
                // console.log('Primeiro item: ', this.first_book)
                // console.log('Último item: ', this.last_book)
            })
            .catch((error) => {
                console.log("Não foi possivel retornar os resultados solicitados para o curso: ", curso, ". Erro: ", error);
            });
        },

        paginaAnterior: function(){
            // Pega o index de livro atual, acha o index dele no array de histórico, volta 1 valor e chama a função novamente com este valor
            console.log('SOLICITANDO PÁGINA ANTERIOR')
            console.log(this.previous_start_points)
            if(this.previous_start_points){
                let previous_point = this.previous_start_points.findIndex(x => x === this.first_book) - 1
                this.retornaLivrosFirebasePorCurso(this.curso_escolhido, this.previous_start_points[previous_point], this.num_limit)
            }    
        },

        proximaPagina: function(){
            console.log('SOLICITANDO PRÓXIMA PÁGINA')
            // Nessa variável armazenamos uma série dos primeiros index de cada pesquisa, dessa forma podemos fazer o botão de voltar
            this.previous_start_points.push(this.first_book)
            // Repare que ultilizamos o last_book como nosso ponto de partida agora
            this.retornaLivrosFirebasePorCurso(this.curso_escolhido, this.last_book+1, this.num_limit)
            console.log('HISTÓRICO: ', this.previous_start_points)
        },

    
    },
    mounted(){
        
        this.curso_escolhido = this.$route.params.curso

        // GAMBIARRA DE NORMATIZAÇÃO
        if (this.curso_escolhido === 'medicina' || this.curso_escolhido === "Medicina"){
            this.curso_escolhido = 'MB Medicina'
        } else if (this.curso_escolhido === 'direito' || this.curso_escolhido === "Direito") {
            this.curso_escolhido = 'MB Jurídica'
        } else if (this.curso_escolhido === 'pedagogia' || this.curso_escolhido === "Pedagogia") {
            this.curso_escolhido = 'MB Pedagógica'
        }


        this.retornaLivrosFirebasePorCurso(this.curso_escolhido, this.num_startAt, this.num_limit)
    },
}

</script>