<template>
    <div class="row">
        <div v-for="livro in targetArray" v-bind:key="livro.id" class="col-6 col-sm-4 col-md-3 col-lg-2 livro my-3">
            <a :href="'https://aluno.uninove.br/biblioteca/minha_biblioteca.php?base=mb&url='+livro.link_acesso">
                <img class="img-fluid" :src="livro.link_capa">
            </a>
            <!-- <p>{{livro.tags_area}}</p> -->
            <p class="titulo-livro">
                <!-- {{livro}} -->
                <!-- <img :src="retornaImgBase(livro.base_dados)" style="height: 50px"> -->
                <!-- {{livro.mms_id}} | {{livro.index}} -->
            </p>
            <p class="titulo-livro">{{livro.titulo}}</p>
            <!-- <p>{{livro.tags_area}}</p>  -->
            <!-- <div pill v-for="tag in livro.tags_area" :key="tag.id" class="m-1" variant="secondary" style="width: fit-content">
                <span v-if="analizaSeTagUninove(tag) >= 0" class="badge bg-primary mx-1">{{tag}}</span>
                 <span v-else class="badge bg-secondary mx-1">{{tag}}</span>
            </div>  -->
        </div>
    </div>
</template>

<script>
import tags_lista from '@/store/categorias.js'


export default {
    name: 'booksRowGallery',
    props: {
        targetArray: Array
    },
    data() {
        return {
           tags_uninove: tags_lista.lista,
        }
    },
    methods: {
        retornaImgBase(base){
            if(base == 'ck') {
                return 'https://www.elsevier.com/__data/assets/image/0018/1013256/ClinicalKey_Elsevier_wordmark.jpg'
            } else if (base == 'pe'){
                return 'https://upload.wikimedia.org/wikipedia/en/thumb/3/35/Pearson_logo.svg/294px-Pearson_logo.svg.png?20180415080110'
            } else if (base == 'mb'){
                return 'https://web03.mouralacerda.edu.br/minhaBiblioteca/include/img/logoMinhaBiblioteca.png'
            } else {
                console.log('Erro na solicitação do primeiro index da base: ', base) 
            }
        },

        analizaSeTagUninove: function(tag){
            // Essa função verifica se é TAG uninove e retorna true ou false
            return this.tags_uninove.findIndex(x => x.path === tag.trim())
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500&family=Noto+Sans:ital,wght@0,400;0,700;1,400&display=swap');

.livro {
    transition: all .3s ease-in-out;
}
.livro:hover {
    transition: all .3s ease-in-out;
    -ms-transform: scale(1.4);
    -webkit-transform: scale(1.4);  
    transform: scale(1.1);
}
.livro img {  
    border-radius: 6px;
    box-shadow: var(--shadow) 0px 3px 8px;
    /* width: 200px; */
    height: max-content;
    margin: 10px;
    max-height: 250px;
    min-width: 150px;
}
.livro img:hover {  
    box-shadow: 0px 1px 5px 2px var(--shadow);
}
.titulo-livro {
    font-family: 'Inter', sans-serif;
    font-family: 'Noto Sans', sans-serif;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    font-weight: 600;
    font-size: 0.9rem;
    text-align: left;
    margin: 0px 10px; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* Extra Extra Small */
@media screen and (min-width: 0px){
    .test{
        border: #32b336 7px solid !important;
    }
    .livro img {  
        /* width: 200px; */
        height: 7.3rem;
        margin: 0px;
        max-height: 250px;
        min-width: 5rem;
    }
    .titulo-livro{
    margin: 8px 5px; 
    }
}

/* Extra Small */
@media screen and (min-width: 350px){
    .test{
        border: #32b336 7px solid !important;
    }
    .livro img {  
        /* width: 200px; */
        height: 15rem;
        margin: 0px;
        max-height: 250px;
        min-width: 7rem;
    }
}

/* Small */
@media screen and (min-width: 576px){
    .test{
        border: #ffa600 7px solid !important;
    }
}

/* Medium*/
@media screen and (min-width: 768px){
    .test{
        border: #41a7ff 7px solid !important;
    }
    .livro img {  
        box-shadow: var(--shadow) 0px 3px 8px;
        /* width: 200px; */
        height: max-content;
        margin: 10px;
        max-height: 250px;
        min-width: 150px;
    }
}

/* Large*/
@media screen and (min-width: 992px){
    .test{
        border: yellow 7px solid !important;
    }
}

/* Extra large */
@media screen and (min-width: 1200px){
    .test{
        border: #ff0053 7px solid !important;
    }
    .container-fluid{
        max-width: 90vw ;
    }
}
</style>
